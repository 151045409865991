define("cheddargorge/components/modal-smart/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "bjCgkREM",
    "block": "[[[8,[39,0],null,[[\"@closeLink\",\"@closeAction\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,1],[\"modal-smart\"],[[\"from\"],[\"cheddargorge/components/modal-smart/styles\"]]]]]],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@closeLink\",\"@closeAction\",\"&default\"],false,[\"ui-modal\",\"local-class\",\"yield\"]]",
    "moduleName": "cheddargorge/components/modal-smart/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});