define("cheddargorge/routes/checkout", ["exports", "@ember/routing/route", "rsvp", "@ember/service", "@ember/object", "@ember/object/computed", "@ember/utils"], function (_exports, _route, _rsvp, _service, _object, _computed, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    headData: (0, _service.inject)(),
    goods: (0, _service.inject)('goods'),
    session: (0, _service.inject)('session-user'),
    basketService: (0, _service.inject)('basket'),
    basket: (0, _computed.alias)('session.basket'),
    async model() {
      let basketItems = await (0, _object.get)(this, 'basket.basketItems');
      let skus = await (0, _rsvp.all)(basketItems.map(basketItem => basketItem.get('sku')));
      await (0, _rsvp.all)(skus.map(sku => sku.get('product')));
      if ((0, _utils.isEmpty)(basketItems)) {
        return this.transitionTo('products');
      }
      if (this.basketService.canCheckout(basketItems) == false) {
        return this.transitionTo('basket');
      }
      const store = this.store;
      return store.createRecord('order', {
        basket: this.basket,
        billingCountry: 'GB',
        marketingOptIn: false,
        metadata: {
          optInMessage: 'Please tick this box if you would like to hear about offers, news and events from Cheddar Gorge & Caves, Longleat Enterprises Ltd. and other Longleat businesses. Please do not tick this box if you are under the age of 16.<br><br>You need to tick this box even if you’ve done so previously but would like to keep hearing from us.'
        }
      });
    },
    async afterModel() {
      (0, _object.set)(this, 'headData.title', 'Checkout | Cheddar Gorge');
      (0, _object.set)(this, 'headData.description', '');
      let basketItems = await (0, _object.get)(this, 'basket.basketItems');
      let skus = await _rsvp.default.all(basketItems.mapBy('sku'));
      await _rsvp.default.all(skus.filter(sku => (0, _utils.isPresent)(sku)).mapBy('product'));
      await _rsvp.default.all(basketItems.mapBy('promotion'));
    },
    _checkOverrideEmail(controller, order) {
      const goods = this.goods;
      let skus = (0, _object.get)(this, 'basket.basketItems').mapBy('sku');
      let partnerSku = skus.find(sku => (0, _object.get)(sku, 'product.slug') === 'partner');
      if ((0, _utils.isEmpty)(partnerSku)) {
        return false;
      }
      let skuFields = goods.fieldsToHash(partnerSku.get('skuFields'));
      let email = (0, _object.get)(skuFields, 'email.firstObject');
      let partnerType = (0, _object.get)(skuFields, 'partner-type.firstObject');
      if (partnerType === 'Accommodation') {
        (0, _object.set)(order, 'emailAddress', email);
        (0, _object.set)(order, 'emailConfirmation', email);
        (0, _object.set)(controller, 'isEmailDisabled', true);
      }
    },
    setupController(controller, order) {
      const store = this.store;
      this._super(...arguments);
      this._checkOverrideEmail(controller, order);
      return store.findAll('country').then(this._setCountries.bind(this, controller, order.get('billingCountry'))).then(this._fetchStates.bind(this)).then(this._setStates.bind(this, controller));
    },
    resetController(controller) {
      (0, _object.set)(controller, 'isEmailDisabled', false);
    },
    actions: {
      createOrder(order) {
        const goods = this.goods;
        (0, _object.set)(this, 'controller.isSubmitting', true);
        return goods.createOrder(order).then(this._get.bind(this, 'orderPaymentMethods')).then(this._mapBy.bind(this, 'shopPaymentMethod')).then(this._mapBy.bind(this, 'paymentMethod')).then(this._createdOrder.bind(this, order)).finally(this._finishedCreatingOrder.bind(this));
      }
    },
    _setCountries(controller, billingCountry, countries) {
      (0, _object.set)(controller, 'countries', countries.sortBy('name'));
      (0, _object.set)(controller, 'selectedCountry', countries.findBy('alpha2', billingCountry));
    },
    _fetchStates() {
      const store = this.store;
      return store.findAll('state');
    },
    _setStates(controller, states) {
      (0, _object.set)(controller, 'states', states.sortBy('name'));
    },
    _get(key, record) {
      return (0, _object.get)(record, key);
    },
    _mapBy(key, list) {
      return _rsvp.default.all(list.mapBy(key));
    },
    _createdOrder(order) {
      const tescoOrderPaymentMethod = this._tescoOrderPaymentMethod(order);
      const tescoMaxPayableAmount = tescoOrderPaymentMethod.maxPayableAmount === undefined ? 0 : tescoOrderPaymentMethod.maxPayableAmount;
      const balance = order.get('balance');
      const session = this.session;
      if (tescoMaxPayableAmount > 0) {
        return this.transitionTo('order.show.payment.tesco', order.get('uuid'));
      }
      if (balance <= 0) {
        return session.resetBasket().then(this._reloadOrder.bind(this, order)).then(this._successfullyResetBasket.bind(this, order));
      }
      return this.transitionTo('order.show.payment', order.get('uuid'));
    },
    _tescoOrderPaymentMethod(order) {
      const orderPaymentMethods = order.get('orderPaymentMethods');
      return orderPaymentMethods.findBy('shopPaymentMethod.paymentMethod.content.name', 'Tesco Clubcard');
    },
    _finishedCreatingOrder() {
      (0, _object.set)(this, 'controller.isSubmitting', false);
    },
    _reloadOrder(order) {
      return order.reload();
    },
    _successfullyResetBasket(order) {
      return this.transitionTo('order.show.complete', order.get('uuid'));
    }
  });
  _exports.default = _default;
});