define("cheddargorge/basket-rules/rules", ["exports", "cheddargorge/basket-rules/christmas-grotto-rules"], function (_exports, christmasGrottoRules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const rules = [...Object.entries(christmasGrottoRules)].filter(_ref => {
    let [key] = _ref;
    return key !== 'default';
  }).map(_ref2 => {
    let [, value] = _ref2;
    return value;
  });
  var _default = rules;
  _exports.default = _default;
});