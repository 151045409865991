define("cheddargorge/controllers/basket", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/utils", "@ember/service"], function (_exports, _controller, _object, _computed, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _controller.default.extend({
    promotionErrors: null,
    basket: (0, _computed.alias)('model'),
    basketService: (0, _service.inject)('basket'),
    allBasketItems: (0, _computed.alias)('model.basketItems'),
    canCheckout: (0, _object.computed)('basketItems.[]', function () {
      return this.basketService.canCheckout(this.basketItems);
    }),
    unresolvedRules: (0, _object.computed)('basketItems.[]', function () {
      return this.basketService.unresolvedRules(this.basketItems);
    }),
    testItems: (0, _object.computed)('model.{isReloading,basketItems.[]}', function () {
      return (0, _object.get)(this, 'model.basketItems').filterBy('isNew', false);
    }),
    persistedBasketItems: (0, _object.computed)('model.{isReloading,basketItems.[]}', function () {
      return (0, _object.get)(this, 'model.basketItems').filterBy('isNew', false);
    }),
    basketItems: (0, _object.computed)('model.basketItems.[]', 'model.isReloading', 'persistedBasketItems', function () {
      return this.persistedBasketItems.filter(basketItem => (0, _utils.isPresent)((0, _object.get)(basketItem, 'promotion.content')) === false);
    }),
    promotionLines: (0, _object.computed)('model.basketItems.[]', 'model.isReloading', 'persistedBasketItems', function () {
      return this.persistedBasketItems.filter(basketItem => (0, _utils.isPresent)((0, _object.get)(basketItem, 'promotion.content')));
    })
  });
  _exports.default = _default;
});