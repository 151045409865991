define("cheddargorge/services/basket", ["exports", "@ember/service", "cheddargorge/basket-rules/rules"], function (_exports, _service, _rules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class basket extends _service.default {
    unresolvedRules(basketItems) {
      return _rules.default.filter(rule => rule.isValid(basketItems) == false);
    }
    blockingRules(basketItems) {
      return this.unresolvedRules(basketItems).filter(rule => rule.preventsCheckout);
    }
    canCheckout(basketItems) {
      return this.blockingRules(basketItems).length === 0;
    }
  }
  _exports.default = basket;
});