define("cheddargorge/templates/corporate-hire/activity", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "S/OVWY/I",
    "block": "[[[8,[39,0],null,[[\"@image\"],[[30,1,[\"heroBannerImage\"]]]],null],[1,\"\\n\\n\"],[6,[39,1],[\"very-light-grain\"],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@label\",\"@link\"],[\"Back to Corporate Hire\",\"business\"]],null],[1,\"\\n\\n    \"],[10,\"h1\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\\n    \"],[1,[28,[35,4],[[30,1,[\"description\"]]],null]],[1,\"\\n\\n\\n\"],[41,[30,1,[\"fileDownload1\"]],[[[1,\"      \"],[10,\"h3\"],[12],[1,\"Resources\"],[13],[1,\"\\n      \"],[8,[39,6],null,[[\"@downloads\"],[[30,0,[\"downloads\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"]],[]]]]],[1,\"\\n\"],[41,[30,1,[\"gridImage1\"]],[[[1,\"  \"],[8,[39,7],null,[[\"@large\",\"@topSmall\",\"@bottomSmall\"],[[30,1,[\"gridImage1\"]],[30,1,[\"gridImage2\"]],[30,1,[\"gridImage3\"]]]],null],[1,\"\\n\"]],[]],null]],[\"@model\"],false,[\"hero-banner\",\"ui-background\",\"section-body\",\"ui-breadcrumbs\",\"wysiwyg-field\",\"if\",\"ui/downloads\",\"picture-grid\"]]",
    "moduleName": "cheddargorge/templates/corporate-hire/activity.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});