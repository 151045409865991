define("cheddargorge/controllers/corporate-hire/activity", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BusinessActivity extends _controller.default {
    get downloads() {
      let downloads = [];
      if (this.model.fileDownload1) {
        downloads.push({
          url: this.model.fileDownload1,
          label: this.model.fileDownloadLabel1
        });
      }
      if (this.model.fileDownload2) {
        downloads.push({
          url: this.model.fileDownload2,
          label: this.model.fileDownloadLabel2
        });
      }
      if (this.model.fileDownload3) {
        downloads.push({
          url: this.model.fileDownload3,
          label: this.model.fileDownloadLabel3
        });
      }
      return downloads;
    }
  }
  _exports.default = BusinessActivity;
});