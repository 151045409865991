define("cheddargorge/basket-rules/christmas-grotto-rules", ["exports", "cheddargorge/basket-rules/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rule4 = _exports.rule3 = _exports.rule2 = _exports.rule1 = void 0;
  const XMAS_PERSON = 'Person with no gift';
  const XMAS_CHILD = 'Child with gift';
  const XMAS_CARER = 'Carer';
  const ENTRY_ADULT = 'Adult';
  const ENTRY_CHILD = 'Child';
  const ENTRY_INFANT = 'Infant';
  const ENTRY_CARER = 'Carer';
  const ENTRY_PERSON = 'Person';
  const XMAS_SLUG = 'christmas-at-cheddar';
  const XMAS_MEMBERS_SLUG = 'members-christmas-at-cheddar-2024';
  const XMAS_STAFF_SLUG = 'staff-day-ticket';

  /**
   * Prevent checkout if there are not enough entry tickets in the basket for the
   * christmas tickets
   */
  const rule1 = {
    preventsCheckout: true,
    title: 'Please now order your day tickets',
    message: `
      <p>
        Anyone taking part in the <strong>Father Christmas Grotto experience</strong> will also require a Cheddar Gorge & Caves Day Ticket for the same date. 
      </p>
      <p>
        Please add <a href='/ticket/gorge-and-caves-explorer-ticket'>Day Tickets</a> to your basket so that you can continue to checkout.
      </p>`,
    isValid: basketItems => {
      if (basketItems.length == 0) {
        return true;
      }

      // Group basket items by date
      const groupedByDate = (0, _helpers.groupBasketItemsByDate)(basketItems);
      let results = Object.values(groupedByDate).map(basketItems => {
        let christmasTickets = (0, _helpers.filterBasketItemsBySlug)(basketItems, XMAS_SLUG);
        let christmasQuantities = (0, _helpers.getTicketTypeQuantities)(christmasTickets, {
          [XMAS_PERSON]: 0,
          [XMAS_CHILD]: 0,
          [XMAS_CARER]: 0
        });
        let entryTickets = (0, _helpers.getEntryTickets)(basketItems);
        let entryQuantities = (0, _helpers.getTicketTypeQuantities)(entryTickets, {
          [ENTRY_ADULT]: 0,
          [ENTRY_CHILD]: 0,
          [ENTRY_INFANT]: 0,
          [ENTRY_CARER]: 0,
          [ENTRY_PERSON]: 0
        });

        /*
          Person with no gift -> Needs either an adult, child or infant entry ticket
          Child with gift -> Needs either a child or infant entry ticket
          Carer -> Needs a carer entry ticket
        */

        // Totals
        const totalChristmasQuantity = christmasQuantities[XMAS_CARER] + christmasQuantities[XMAS_CHILD] + christmasQuantities[XMAS_PERSON];

        // For tickets that can be used for any age
        let genericEntryQuantity = entryQuantities[ENTRY_PERSON];

        // Quick return - if our generic tickets cover all the christmas tickets
        // then we are good
        if (genericEntryQuantity >= totalChristmasQuantity) {
          return true;
        }
        let carerResult = (0, _helpers.calculateValidity)(christmasQuantities[XMAS_CARER], entryQuantities[ENTRY_CARER], genericEntryQuantity);

        // This is confusing but ENTRY_CHILD and ENTRY_PERSON both qualify
        // XMAS_CHILD and XMAS_PERSON, so they must be treated as gwenerics from
        // here on.
        let remainder = carerResult.remainder + entryQuantities[ENTRY_CHILD] + entryQuantities[ENTRY_PERSON];
        let childResult = (0, _helpers.calculateValidity)(christmasQuantities[XMAS_CHILD], entryQuantities[ENTRY_INFANT], remainder);
        remainder = childResult.remainder;
        let personResult = (0, _helpers.calculateValidity)(christmasQuantities[XMAS_PERSON], entryQuantities[ENTRY_ADULT], remainder);
        return carerResult.isValid && childResult.isValid && personResult.isValid;
      });
      return results.every(result => result === true);
    }
  };

  /**
   * Warning if there are only adult christmas tickets in the basket
   */
  _exports.rule1 = rule1;
  const rule2 = {
    preventsCheckout: false,
    title: `Warning - You only have 'no-gift' grotto experience tickets in your basket.`,
    message: `
      <p>
        This festive experience is designed for children. All adults booking on a session must be accompanying a child who is receiving a gift from Father Christmas.
      </p>
      `,
    isValid: basketItems => {
      if (basketItems.length == 0) {
        return true;
      }
      const groupedByDate = (0, _helpers.groupBasketItemsByDate)(basketItems);
      let results = Object.values(groupedByDate).map(basketItems => {
        let christmasTickets = (0, _helpers.filterBasketItemsBySlug)(basketItems, XMAS_SLUG);
        let memberChristmasTickets = (0, _helpers.filterBasketItemsBySlug)(basketItems, XMAS_MEMBERS_SLUG);
        let allChristmasTickets = [...christmasTickets, ...memberChristmasTickets];
        let christmasQuantities = (0, _helpers.getTicketTypeQuantities)(allChristmasTickets, {
          [XMAS_PERSON]: 0,
          [XMAS_CHILD]: 0,
          [XMAS_CARER]: 0
        });
        return !(christmasQuantities[XMAS_PERSON] + christmasQuantities[XMAS_CARER] > 0 && christmasQuantities[XMAS_CHILD] == 0);
      });
      return results.every(result => result === true);
    }
  };

  /**
   * Warning if there are only child christmas tickets in the basket
   */
  _exports.rule2 = rule2;
  const rule3 = {
    preventsCheckout: false,
    title: `Warning - You only have Child Grotto Experience Tickets in your basket`,
    message: `
      <p>
        Please remember that any children visiting Cheddar Gorge & Caves and meeting Father Christmas must be accompanied at all times by a responsible adult who has a Grotto Experience ticket.
      </p>
      `,
    isValid: basketItems => {
      if (basketItems.length == 0) {
        return true;
      }
      const groupedByDate = (0, _helpers.groupBasketItemsByDate)(basketItems);
      let results = Object.values(groupedByDate).map(basketItems => {
        let christmasTickets = (0, _helpers.filterBasketItemsBySlug)(basketItems, XMAS_SLUG);
        let christmasQuantities = (0, _helpers.getTicketTypeQuantities)(christmasTickets, {
          [XMAS_PERSON]: 0,
          [XMAS_CHILD]: 0,
          [XMAS_CARER]: 0
        });
        return !(christmasQuantities[XMAS_PERSON] + christmasQuantities[XMAS_CARER] == 0 && christmasQuantities[XMAS_CHILD] > 0);
      });
      return results.every(result => result === true);
    }
  };

  /**
   * Hacky rule to enable staff members to add grotto tickets to their basket (a faux upsell)
   */
  _exports.rule3 = rule3;
  const rule4 = {
    preventsCheckout: false,
    title: 'Add Father Christmas Grotto experience tickets?',
    message: `
      <p>
        Add <a href='ticket/christmas-at-cheddar'>Father Christmas Grotto experience tickets</a> to your basket.
      </p>`,
    isValid: basketItems => {
      if (basketItems.length == 0) {
        return true;
      }

      // Group basket items by date
      const groupedByDate = (0, _helpers.groupBasketItemsByDate)(basketItems);
      let results = Object.entries(groupedByDate).map(_ref => {
        let [date, basketItems] = _ref;
        let staffTickets = (0, _helpers.filterBasketItemsBySlug)(basketItems, XMAS_STAFF_SLUG);
        let staffQuantities = (0, _helpers.getTicketTypeQuantities)(staffTickets, {
          [ENTRY_PERSON]: 0
        });
        if (staffQuantities[ENTRY_PERSON] == 0) {
          return true;
        }
        const ticketDate = new Date(date);
        const xmasStartDate = new Date(ticketDate.getFullYear(), 10, 16);
        console.log(ticketDate, xmasStartDate, ticketDate < xmasStartDate);
        return ticketDate < xmasStartDate;
      });
      return results.every(result => result === true);
    }
  };
  _exports.rule4 = rule4;
});