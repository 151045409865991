define("cheddargorge/components/ui-modal/component", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    didInsertElement() {
      document.querySelector('body').classList.add('modal-open');
    },
    willDestroyElement() {
      document.querySelector('body').classList.remove('modal-open');
    }
  });
  _exports.default = _default;
});