define("cheddargorge/components/ui/message.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ui-message": "_ui-message_81l9lh",
    "theme-error": "_theme-error_81l9lh",
    "theme-soft-error": "_theme-soft-error_81l9lh",
    "theme-good": "_theme-good_81l9lh",
    "is-centered": "_is-centered_81l9lh",
    "has-bottom-margin": "_has-bottom-margin_81l9lh"
  };
  _exports.default = _default;
});