define("cheddargorge/routes/add-extras", ["exports", "@ember/routing/route", "@ember/service", "cheddargorge/helpers/load"], function (_exports, _route, _service, _load) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let AddExtras = (_class = class AddExtras extends _route.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "headData", _descriptor, this);
      _initializerDefineProperty(this, "sessionUser", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
    }
    /**
     * Initiates the load data and makes it available in the model
     *
     */
    model() {
      let basketData = (0, _load.load)(this.loadBasketData(this.store, this.sessionUser));
      let model = {
        productsData: (0, _load.load)(this.loadProductsData(this.store, this.router, basketData))
      };
      this.headData.set('title', 'Add extras | Cheddar Gorge');
      this.headData.set('description', '');
      return model;
    }

    /**
     * Loads the Basket
     *
     * @param {Store} store
     * @param {any} sessionUser
     */
    async loadBasketData(store, sessionUser) {
      let basket = sessionUser.get('basket');
      let loadedBasket = await store.query('basket', {
        filter: {
          id: basket.get('id')
        },
        include: 'basket_items.sku.product'
      });
      return loadedBasket;
    }

    /**
     * Loads the products
     *
     * @param {Store} store
     * @param {any} basketData
     */
    async loadProductsData(store, router, basketData) {
      await basketData;
      let products = await store.query('product', {
        filter: {
          custom_query: [['product_list', 'like', 'Extras']]
        },
        include: 'skus,product_fields'
      });
      let basketSlugs = this.sessionUser.get('basket').get('basketItems').mapBy('sku.product.slug').uniq();
      const filteredProducts = products.filter(product => {
        const associatedProductSlugs = this.associatedProductSlugsFor(product);
        if (associatedProductSlugs.length > 0) {
          return associatedProductSlugs.any(slug => basketSlugs.includes(slug));
        }
        return true;
      });
      if (filteredProducts.length == 0) {
        router.transitionTo('checkout');
      }
      return filteredProducts;
    }
    associatedProductSlugsFor(product) {
      let associatedProducts = product.get(
      //@ts-ignore
      'attributes.associated_products');
      if (associatedProducts.length > 0) {
        return associatedProducts.split(',').map(slug => slug.trim());
      }
      return [];
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "headData", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sessionUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = AddExtras;
});