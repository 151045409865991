define("cheddargorge/components/ui/downloads", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @downloads.length}}
    <div local-class="downloads">
      {{#each @downloads as |download|}}
        <a href={{download.url}} target="_blank" rel="noopener noreferrer" local-class="download">
          {{svg-jar "circle-arrow-down" height="20px" width="20px" local-class="icon"}}
          <div local-class="label">{{download.label}}</div>
        </a>
      {{/each}}
    </div>
  {{/if}}
  */
  {
    "id": "phDgdEXA",
    "block": "[[[41,[30,1,[\"length\"]],[[[1,\"  \"],[10,0],[15,0,[29,[[28,[37,1],[\"downloads\"],[[\"from\"],[\"cheddargorge/components/ui/downloads.scss\"]]]]]],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,1]],null]],null],null,[[[1,\"      \"],[10,3],[15,0,[29,[[28,[37,1],[\"download\"],[[\"from\"],[\"cheddargorge/components/ui/downloads.scss\"]]]]]],[15,6,[30,2,[\"url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"circle-arrow-down\"],[[\"height\",\"width\",\"class\"],[\"20px\",\"20px\",[28,[37,5],[[28,[37,1],[\"icon\"],[[\"from\"],[\"cheddargorge/components/ui/downloads.scss\"]]]],null]]]]],[1,\"\\n        \"],[10,0],[15,0,[29,[[28,[37,1],[\"label\"],[[\"from\"],[\"cheddargorge/components/ui/downloads.scss\"]]]]]],[12],[1,[30,2,[\"label\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@downloads\",\"download\"],false,[\"if\",\"local-class\",\"each\",\"-track-array\",\"svg-jar\",\"concat\"]]",
    "moduleName": "cheddargorge/components/ui/downloads.hbs",
    "isStrictMode": false
  });
  class UiDownloads extends _component2.default {}
  _exports.default = UiDownloads;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiDownloads);
});