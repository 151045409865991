define("cheddargorge/components/ui-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "VDSAp/5E",
    "block": "[[[6,[39,0],null,[[\"to\"],[\"wormhole\"]],[[\"default\"],[[[[1,\"  \"],[10,0],[15,0,[29,[[28,[37,1],[[28,[37,2],[\"ui-modal \",[52,[30,1],\"is-narrow\"],\" \",[52,[30,2],\"is-medium\"]],null]],[[\"from\"],[\"cheddargorge/components/ui-modal/styles\"]]]]]],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"ui-modal--modal-dialog \",[28,[37,1],[\"modal-dialog\"],[[\"from\"],[\"cheddargorge/components/ui-modal/styles\"]]]]]],[12],[1,\"\\n\"],[41,[33,4],[[[1,\"        \"],[6,[39,5],null,[[\"class\",\"route\"],[[28,[37,2],[[28,[37,1],[\"close-btn\"],[[\"from\"],[\"cheddargorge/components/ui-modal/styles\"]]]],null],[33,4]]],[[\"default\"],[[[[1,\"x\"]],[]]]]],[1,\"\\n\"]],[]],null],[41,[33,6],[[[1,\"        \"],[11,0],[16,0,[29,[[28,[37,1],[\"close-btn\"],[[\"from\"],[\"cheddargorge/components/ui-modal/styles\"]]]]]],[4,[38,7],[[30,0],[33,6]],null],[12],[1,\"x\"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@isNarrow\",\"@isMedium\",\"&default\"],false,[\"ember-wormhole\",\"local-class\",\"concat\",\"if\",\"closeLink\",\"link-to\",\"closeAction\",\"action\",\"yield\"]]",
    "moduleName": "cheddargorge/components/ui-modal/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});