define("cheddargorge/basket-rules/helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculateValidity = calculateValidity;
  _exports.filterBasketItemsBySlug = filterBasketItemsBySlug;
  _exports.getEntryTickets = getEntryTickets;
  _exports.getTicketTypeQuantities = getTicketTypeQuantities;
  _exports.groupBasketItemsByDate = groupBasketItemsByDate;
  _exports.groupBasketItemsByTicketType = groupBasketItemsByTicketType;
  /**
   * Filters basket items by a specific product slug.
   *
   * This function iterates over the provided basket items and selects those
   * that match the specified product slug.
   *
   * @param basketItems - An array of BasketItem objects to be filtered.
   * @param slug - The product slug to filter the basket items by.
   * @returns An array of BasketItem objects that have the specified product slug.
   */
  function filterBasketItemsBySlug(basketItems, slug) {
    return basketItems.filter(basketItem =>
    //@ts-ignore
    basketItem.get('sku.product.slug') === slug);
  }

  /**
   * Groups basket items by their ticket type.
   *
   * This function organizes the provided basket items into groups based on
   * their ticket type, identified by the 'name' attribute in the SKU.
   *
   * @param basketItems - An array of BasketItem objects to be grouped.
   * @returns An object where each key is a ticket type name and the value is an
   * array of BasketItem objects that have that ticket type.
   */
  function groupBasketItemsByTicketType(basketItems) {
    return basketItems.reduce((acc, basketItem) => {
      //@ts-ignore
      const name = basketItem.get('sku.attrs.name');
      if (!acc[name]) {
        acc[name] = [];
      }
      acc[name].push(basketItem);
      return acc;
    }, {});
  }

  /**
   * Calculates the quantities of each ticket type in the basket items.
   *
   * This function computes the total quantity for each ticket type present in
   * the basket items and merges these quantities with the provided default values.
   *
   * @param basketItems - An array of BasketItem objects to calculate quantities for.
   * @param defaults - An optional object containing default quantities for ticket types.
   * @returns An object where each key is a ticket type name and the value is the
   * total quantity of that ticket type in the basket items.
   */
  function getTicketTypeQuantities(basketItems) {
    let defaults = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let ticketTypes = groupBasketItemsByTicketType(basketItems);
    let quantities = {};
    Object.keys(ticketTypes).map(name => {
      quantities[name] = ticketTypes[name].reduce((sum, basketItem) => sum + basketItem.quantity, 0);
    });
    return {
      ...defaults,
      ...quantities
    };
  }

  /**
   * Groups basket items by their bookable date.
   *
   * This function takes an array of BasketItem objects and organizes them into
   * groups based on their bookable date. Each group is represented as an array
   * of BasketItem objects that share the same bookable date.
   *
   * @param basketItems - An array of BasketItem objects to be grouped.
   * @returns An object where each key is a bookable date (as a string) and the
   * value is an array of BasketItem objects that have that bookable date.
   */
  function groupBasketItemsByDate(basketItems) {
    return basketItems.reduce((acc, basketItem) => {
      //@ts-ignore
      const date = basketItem.get('sku.attrs').bookableDate;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(basketItem);
      return acc;
    }, {});
  }

  /**
   * Filters and returns the entry tickets from a list of basket items.
   *
   * This function iterates over the provided basket items and selects those
   * that are marked as entry tickets. An entry ticket is identified by the
   * `isEntryTicket` attribute set to true in the product attributes.
   *
   * @param basketItems - An array of BasketItem objects to be filtered.
   * @returns An array of BasketItem objects that are entry tickets.
   */
  function getEntryTickets(basketItems) {
    return basketItems.filter(basketItem =>
    //@ts-ignore
    basketItem.get('sku.product.attrs.isEntryTicket') == true);
  }

  /**
   * Calculates the validity of a target quantity against qualifying and remainder
   * quantities.
   *
   * This function checks if the target quantity can be satisfied by the
   * qualifying quantity. If the qualifying quantity is insufficient, it checks if
   * the remainder can cover the deficit and returns the updated remainder.
   *
   * @param target - The target quantity that needs to be validated.
   * @param qualifying - The quantity that directly qualifies towards the target.
   * @param remainder - The additional quantity that can be used to cover any
   * deficit.
   * @returns An object containing:
   *  - isValid: A boolean indicating if the target is valid.
   *  - remainder: The updated remainder after covering any deficit.
   */
  function calculateValidity(target, qualifying, remainder) {
    let isValid = false;
    const deficit = target - qualifying;
    if (deficit <= 0) {
      isValid = true;
    } else if (remainder >= deficit) {
      remainder = remainder - deficit;
      isValid = true;
    }
    return {
      isValid,
      remainder: remainder
    };
  }
});